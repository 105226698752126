var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table"},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm doanh nghiệp',"arrayExcel":[],"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideImportFile":false,"hideExportFile":false,"hideDowload":false,"hideAdd":_vm.roleUser!=='EducationDepartment'},on:{"clickAdd":_vm.showModalCreate,"clickDelete":_vm.deleteItems,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataTable || [],"select-options":{
      enabled: _vm.hideButon,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{staticClass:"light-code",attrs:{"pill":""}},[_c('span',{staticClass:"text-name"},[_vm._v(_vm._s(props.row.code))])])],1):(props.column.field === 'actionFunction'&& _vm.roleUser !== 'EducationDepartment')?_c('span',[_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.handlePageClick}}),_c('modal-add-business',{attrs:{"id":_vm.modalAddBusiness,"dataListTable":_vm.dataListTable,"totalRecord":_vm.totalRecordNotRegister,"queryGetList":_vm.queryGetList,"isLoading":_vm.isLoading},on:{"addGroupUser":_vm.addGroupUserHandle,"search":_vm.searchUsers,"pageChangeModal":_vm.pageChangeModal,"handleFilter":_vm.handleFilter}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }