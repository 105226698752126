<template>
  <b-modal
    :id="id"
    size="lg"
    title="Thêm nhóm người dùng"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    @ok="addGroupUser"
  >
    <b-row class="header-action">
      <b-col>
        <v-select
          v-model="bussinessClass"
          :reduce="data => data.id"
          :label="'name'"
          :options="dataTypeOfBussiness"
          :placeholder="'Loại hình doanh nghiệp'"
          @input="handleFilter"
        >
          <template v-slot:no-options="{ searching }">
            <template v-if="searching">
              Không có kết quả.
            </template>
            <em
              v-else
            >Không có dữ liệu</em>
          </template>
        </v-select>
      </b-col>
      <b-col>
        <search @change="search" />
      </b-col>
    </b-row>
    <vue-good-table
      style-class="vgt-table"
      :columns="columnModal"
      :isLoading="isLoading"
      :rows="dataListTable || []"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTableModal"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'code'"
          class="text-nowrap"
        >
          <b-badge
            pill
            class="light-code-group"
          >
            <span class="text-name">{{ $t(props.row.code) }}</span>
          </b-badge>
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="queryGetList.pageNumber"
      @pageClick="pageChangeModal"
    />
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import {
  BMedia,
  BDropdown, BDropdownItem, BRow, BCol, BFormGroup, BBadge, BModal, BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Search from '@/components/search/Search.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormGroup,
    BMedia,
    Search,
    BBadge,
    BModal,
    BButton,
    vSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataListTable: {
      type: Array,
      default: _ => [],
    },
    totalRecord: {
      type: Number,
      default: 0,
    },
    queryGetList: {
      type: Object,
      default: _ => {},
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bussinessClass: '',
      dataTypeOfBussiness: [
        {
          id: 'Bussiness',
          name: 'Doanh nghiệp',
        },
        {
          id: 'Accreditation',
          name: 'Tổ chức kiểm định',
        },
        {
          id: 'Training',
          name: 'Tổ chức huấn luyện',
        },
        {
          id: 'Health',
          name: 'Trung tâm y tế',
        },
        {
          id: 'JobCenter',
          name: 'Trung tâm giới thiệu việc làm',
        },
        {
          id: 'StateAgencies',
          name: 'Quận/Huyện, Phường/Xã',
        },
      ],
      totalRecordModal: 0,
      columnModal: [
        {
          label: 'MÃ DOANH NGHIỆP',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN DOANH NGHIỆP',
          field: 'name',
          sortable: false,
        },
      ],
      listModel: [],
    }
  },
  methods: {

    // tìm kiếm
    search(val) {
      this.$emit('search', val)
    },

    // phân trang modal
    pageChangeModal(pageNumber, pageSize) {
      this.$emit('pageChangeModal', pageNumber, pageSize)
    },

    // lấy giá trị cần chọn
    selectRowTableModal(val) {
      this.listModel = []
      val.selectedRows.forEach(element => {
        const { id } = element
        this.listModel.push(id)
      })
    },
    handleFilter(val) {
      this.$emit('handleFilter', val)
    },
    // truyền dữ liệu qua handle Add
    addGroupUser(evt) {
      evt.preventDefault()
      if (this.listModel.length === 0) {
        const message = 'Chọn ít nhất 1 doanh nghiệp'
        this.$root.$bvToast.toast(message, {
          title: 'Thông báo', variant: 'warning', toaster: this.$toastPosition, solid: true,
        })
      } else {
        this.$emit('addGroupUser', this.listModel)
      }
    },
  },
}
</script>
<style lang="scss">
.light-code-group {
  background: rgba(34, 127, 244, 0.12);
  border-radius: 4px;
}
</style>
